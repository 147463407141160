import uuidv1 from 'uuid/v1';

// HEAD DATA
export const headData = {
  title: 'Jorge Arias | Asesor', // e.g: 'Name | Developer'
  lang: 'es', // e.g: en, es, fr, jp
  description: 'Servicios: Asesoría Laboral, Fiscal y Contable y Asesoría Jurídica', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hola, soy',
  name: 'Jorge Arias.',
  subtitle: 'Asesor y consultor.',
  cta: 'Saber más',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: 'Doble Licenciado en Derecho y ADE. Buscando constantemente aprender y aportar algo nuevo cada día. Apasionado por la tecnología, y aportando mi granito de arena para la imparable transformación digital del sector legal. ',
  paragraphTwo: 'Abogado. Actualmente trabajando como agente inspector.',
  paragraphThree: 'Servicios:',
  paragraphFour: 'Asesoría Laboral, Fiscal y Contable',
  paragraphFive: 'Asesoría Jurídica',
  resume: 'https://www.linkedin.com/in/jorge-arias-gisbert-b03906148/', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv1(),
    title: 'Asesor fiscal',
    info: 'Más de 3 años asesorando a auntónomos y particulares para ahorrar tiempo y dinero en sus declaraciones.',
    info2: '',
    url: '',
  },
  {
    id: uuidv1(),
    title: 'Asesor de freelancers del sector tecnológico',
    info: 'Centrado en profesionales tecnológicos, emprendimiento y legaltech.',
    info2: 'Trámites con la Seguridad Social, facturación, licencias e impuestos. Protección de datos y Privacidad.',
    url: '',
  },
  {
    id: uuidv1(),
    title: 'Consultoría legal de eventos',
    info: 'Contabilidad y fiscalidad de eventos como "Women Techmaker Alicante".',
    info2: 'Creación de asociaciones.',
    url: '',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: 'Email',
  email: 'jariasgisbert@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: uuidv1(),
      name: 'twitter',
      url: 'https://www.twitter.com/JorgeAbert',
    },
    {
      id: uuidv1(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/jorge-arias-gisbert-b03906148/',
    },
    {
      id: uuidv1(),
      name: 'whatsapp',
      url: 'https://wa.me/+34699670587',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
